import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Menu,
  Radio,
  RadioGroup,
  Snackbar,
  TextareaAutosize,
  Switch,
  FormHelperText
} from "@mui/material";
import {
  Link,
  useParams,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
// import styles from "./userform.module.css"
import "./userform.module.css";
import AutocompleteWithApi from "./Autocomplete";


let astrotoolkitArr = [
  {
    name: "Nativity Birth Chart",
    val: "western_horoscope_v2"
  },
  {
    name: "Solar Return",
    val: "solar_return_v2"
  },
  {
    name: "Weekly Transits",
    val: "tropical_transits_weekly_v2"
  },
  {
    name: "Monthly Transits + Lunar Return",
    val: "tropical_transits_monthly_v3"
  },
  {
    name: "Romantic Relationships",
    val: "romantic_forecast_report_tropical_v2"
  },
  {
    name: "Friendship Relationships",
    val: "friendship_report_tropical_v2"
  },
  {
    name: "Business Relationship",
    val: "business_partner_v2"
  },
  {
    name: "Predictive Event (Horary)",
    val: "horary_chart_v2"
  },
  {
    name: "Jupiter Return",
    val: "jupiter_return_v2"
  },
  {
    name: "Saturn Return",
    val: "saturn_return_v2"
  },
  {
    name: "Mars Return",
    val: "mars_return_v2"
  },

]

let tarotToolkitArr = [
  {
    name: '3 Card Basic Question Spread',
    val: 'tarotCard3'
  },
  {
    name: '5 Card Complex Question Spread',
    val: 'tarotCard7'
  },
  {
    name: '7 Card 6 month forward review',
    val: 'tarotCard5'
  },
  {
    name: '7 Card Horseshoe Spread (Major read)',
    val: 'tarotCar'
  },
  {
    name: '10 Card Relationship Spread',
    val: 'tarotCard6'
  },
  {
    name: '10 Card Celtic Cross (Major read)',
    val: 'tarotCard9'
  },
  {
    name: '12 Card Astrological Spread (Major read)',
    val: 'tarotCard8'
  },
]

const UserForm = ({ setUser, user, setError, error }) => {

  const [optionArr, setOptionArr] = useState(astrotoolkitArr);
  const [product, setProduct] = useState(astrotoolkitArr[0].name);

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      'Product_Deck': astrotoolkitArr[0].val,
      'Product_Deck_name': astrotoolkitArr[0].name,
    }));
  }, [])


  const handleInput = (e) => {

    let name = e.target.name;
    let value = e.target.value;

    setError((prevError) => ({
      ...prevError,
      [name]: '',
    }));

    if (name == 'session_for') {
      if (value == 'Astro Toolkit') {
        setOptionArr(astrotoolkitArr)
        setUser((prevUser) => ({
          ...prevUser,
          'Product_Deck': astrotoolkitArr[0].val,
          'Product_Deck_name': astrotoolkitArr[0].name,
          'SecondaryUrl': `horoscope?tab=`,
          'session_for': value
        }));
      } else {
        setOptionArr(tarotToolkitArr)

        setUser((prevUser) => ({
          ...prevUser,
          'Product_Deck_name': tarotToolkitArr[0].name,
          'Product_Deck': tarotToolkitArr[0].val,
          'SecondaryUrl': `tarot-card/`,
          'session_for': value
        }));

      }
    } else if (name == 'Product_Deck') {
      let Product_Deck_name_Obj = optionArr.find(item => item.val === value);
      const Product_Deck_name = Product_Deck_name_Obj ? Product_Deck_name_Obj.name : 'Not found';
      setProduct(Product_Deck_name)
      setUser((prevUser) => ({
        ...prevUser,
        'Product_Deck': value,
        'Product_Deck_name': Product_Deck_name
      }));

    } else {

      setUser((prevUser) => ({
        ...prevUser,
        [name]: value != 'on' ? value : e.target.checked,
      }));



    }


  };




  const handleAutocompleteSelect = (value) => {
    setError((error) => ({
      ...error,
      'city': '',

    }));
    setUser((prevUser) => ({
      ...prevUser,
      'city': value?.val,
      'lat': value?.key?.lat,
      'lon': value?.key?.lng,
      'tzone': value?.key?.timezone?.offset_string
    }));
  };

  const handleAutocompleteSelectPartenerCity = (value) => {
    setError((error) => ({
      ...error,
      'partner_city': '',

    }));
    setUser((prevUser) => ({
      ...prevUser,
      'partner_city': value?.val,
      'partner_lat': value?.key?.lat,
      'partner_lon': value?.key?.lng,
      'partner_tzone': value?.key?.timezone?.offset_string
    }));
  };

  const handleSubmit = (e) => {
    let errorObject = {};

    console.log("user=====>>>>", user);

    if (!user?.full_name) {
      errorObject.name = "Name is Required";
    }

    if (!user?.city) {
      errorObject.city = "city is Required";
    }

    if (!user?.dob) {
      errorObject.dob = "DOB is Required";
    }



    if (!user?.phone) {
      errorObject.phone = "Contact Number is Required";
    } else if (user.phone.toString().length != 14) {
      errorObject.phone = "* Contact Number must be 10 digits";
    }

    if (Object.keys(errorObject).length > 0) {
      setError(errorObject);
    } else {
      // submitapi();
    }
  };

  const phoneFormatting = (event) => {
    if (event.target.value.split("").length <= 14) {
      var formatted = formatPhoneText(event.target.value);
      let name = event.target.name;
      // user.phone = formatted;
      setUser({ ...user, [name]: formatted });
    }
  };


  function formatPhoneText(value) {
    value = value.trim().replaceAll("-", "");
    value = value.trim().replaceAll("(", "");
    value = value.trim().replaceAll(")", "");
    value = value.trim().replaceAll(" ", "");

    if (value.length > 3 && value.length <= 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
    else if (value.length > 6)
      value =
        "(" +
        value.slice(0, 3) +
        ")" +
        " " +
        value.slice(3, 6) +
        "-" +
        value.slice(6);
    return value;
  }


  function getNextWeekStart() {
    const today = new Date();

    // Calculate days until next Sunday
    const daysUntilSunday = 7 - today.getDay();

    // Set the date to the next Sunday
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilSunday);
    const date = new Date(nextSunday);
    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  }



  function getNextMonthStart() {
    const today = new Date();

    // Clone today's date, set month to the next month, and day to the 1st
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    const date = new Date(nextMonth);
    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  }
  return (
    <>
      <div className="user_from_modal_full_width">

        <div className="information_details_sec">

          <div className="from_field">
            <h1>Choose Season Type</h1>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                name="session_for"
                value={user?.session_for ? user.session_for : ""}
                onChange={handleInput}
              >
                <MenuItem value={'Astro Toolkit'}>Astro Toolkit</MenuItem>
                <MenuItem value={'Tarot Toolkit'}>Tarot Toolkit</MenuItem>
              </Select>
            </FormControl>
          </div>



          <div className="from_field">
            <h1>Choose Product/Deck</h1>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                name="Product_Deck"
                value={user?.Product_Deck ? user.Product_Deck : ""}
                onChange={handleInput}
              >

                {optionArr.map((value, index) => (<MenuItem key={index} value={value.val}>{value.name}</MenuItem>))}
              </Select>
            </FormControl>
          </div>


        </div>



        <div className="information_details_sec">

          {/* <span>Personal Information</span> */}
          <h1>Personal Information</h1>

          <div className="from_sec_main_wrpr">

            <div className="from_field">
              <InputLabel>Full Name</InputLabel>

              <TextField
                className="addemail"
                label="Type your Full Name here..."
                name="full_name"
                value={user?.full_name ? user.full_name : ""}
                onChange={handleInput}
                onKeyDown={() => {
                  error.full_name = "";
                }}
              />
              {error.full_name && (
                <FormHelperText sx={{ color: "red" }}>
                  {error.full_name}
                </FormHelperText>
              )}
            </div>


            <div className="from_field">
              <InputLabel>Phone</InputLabel>

              <TextField
                className="addemail"
                label="Phone"
                name="phone"
                value={user?.phone ? user.phone : ""}
                onChange={(e) => phoneFormatting(e)}
                onKeyPress={(e) => {
                  error.phone = "";
                  var charTyped = e.keyCode;

                  if (e.which < 48 || e.which > 57) {
                    e.preventDefault();
                  } else {
                    let input = e.target.value;

                    let result = input.replace(/\D/g, "");
                  }
                }}
              />
              {error.phone && (
                <FormHelperText sx={{ color: "red" }}>
                  {error.phone}
                </FormHelperText>
              )}
            </div>




            <div className="from_field date_field">
              <InputLabel>Date of Birth</InputLabel>

              <input type="date" id="dob" name="dob" onChange={handleInput} onKeyDown={() => {
                error.dob = "";
              }} />
              {error.dob && (
                <FormHelperText sx={{ color: "red" }}>
                  {error.dob}
                </FormHelperText>
              )}
            </div>


            <div className="from_field date_field">
              <InputLabel>Time of Birth</InputLabel>

              <input type="time" id="time_of_birth" name="time_of_birth" onChange={handleInput} ></input>
              {error.time_of_birth && (
                <FormHelperText sx={{ color: "red" }}>
                  {error.time_of_birth}
                </FormHelperText>
              )}
            </div>


            <div className="from_field">
              <InputLabel>Country</InputLabel>

              <TextField
                className="addemail"
                label="Type your Country here..."
                name="country"
                value={user?.country ? user.country : ""}
                onChange={handleInput}
                error={error.country ? true : false}
                helperText={error.country}
                onKeyDown={() => {
                  error.country = "";
                }}
              />
            </div>



            <div className="from_field autocompleteCls selfCity">
              <InputLabel>City: (e.g. Altanta)</InputLabel>

              <AutocompleteWithApi onSelect={handleAutocompleteSelect}
                label={'Search yor City here...'}
                api={'https://d36fwfwo4vnk9h.cloudfront.net/astro-ai/fetch_city_with_latLon'}
              />
              {error.city && (
                <FormHelperText sx={{ color: "red" }}>
                  {error.city}
                </FormHelperText>
              )}
            </div>




            <div className="from_field">
              <InputLabel>Occupation</InputLabel>

              <TextField
                className="addemail"
                label="Type your Occupation here.."
                name="occupation"
                value={user?.occupation ? user.occupation : ""}
                onChange={handleInput}
                error={error.occupation ? true : false}
                helperText={error.occupation}
                onKeyDown={() => {
                  error.occupation = "";
                }}
              />
            </div>

            {product == 'Weekly Transits' &&
              <div className="from_field date_field">
                <InputLabel>Select Week</InputLabel>

                <input type="date" id="selected_week" name="selected_week" onChange={handleInput} min={getNextWeekStart()} />
                {error.selected_week && (
                  <FormHelperText sx={{ color: "red" }}>
                    {error.selected_week}
                  </FormHelperText>
                )}
              </div>
            }

            {product == 'Monthly Transits + Lunar Return' &&
              <div className="from_field date_field">
                <InputLabel>Select Month</InputLabel>

                <input type="date" id="selected_month" name="selected_month" onChange={handleInput} min={getNextMonthStart()}  />
                {error.selected_month && (
                  <FormHelperText sx={{ color: "red" }}>
                    {error.selected_month}
                  </FormHelperText>
                )}
              </div>
            }

            {((product == 'Romantic Relationships') || (product == 'Friendship Relationships') || (product == 'Business Relationship')) &&

              <>
                <div className="from_field date_field">
                  <InputLabel>Partner Date of Birth</InputLabel>

                  <input type="date" id="partner_dob" name="partner_dob" onChange={handleInput} />
                  {error.partner_dob && (
                    <FormHelperText sx={{ color: "red" }}>
                      {error.partner_dob}
                    </FormHelperText>
                  )}
                </div>


                <div className="from_field date_field">
                  <InputLabel>Partner Time of Birth</InputLabel>

                  <input type="time" id="partner_time_of_birth" name="partner_time_of_birth" onChange={handleInput} ></input>
                  {error.partner_time_of_birth && (
                    <FormHelperText sx={{ color: "red" }}>
                      {error.partner_time_of_birth}
                    </FormHelperText>
                  )}
                </div>


                <div className="from_field autocompleteCls partnerCity">
                  <InputLabel>Partner City: (e.g. Altanta)</InputLabel>

                  <AutocompleteWithApi onSelect={handleAutocompleteSelectPartenerCity}
                    label={'Search yor City here...'}
                    api={'https://d36fwfwo4vnk9h.cloudfront.net/astro-ai/fetch_city_with_latLon'}
                  />
                  {error.partner_city && (
                    <FormHelperText sx={{ color: "red" }}>
                      {error.partner_city}
                    </FormHelperText>
                  )}
                </div>



              </>
            }



            {product == 'Predictive Event (Horary)' &&
              <div className="from_field sub_sec">
                <InputLabel>Your Question</InputLabel>

                <TextareaAutosize
                  minRows={4}
                  className="addemail"
                  label="Your Question"
                  name="your_question"
                  value={user?.your_question ? user.your_question : ""}
                  onChange={handleInput}
                  onKeyDown={() => {
                    error.your_question = "";
                  }}
                />
                {error.your_question && (
                  <FormHelperText sx={{ color: "red" }}>
                    {error.your_question}
                  </FormHelperText>
                )}
              </div>}




          </div>

          <div className="from_field information_details_sec">
            <FormControl>
              <FormLabel className="heading_sub">Relationship Status</FormLabel>
              <RadioGroup className="relation_status_wrpr"
                defaultValue="Single"
                name="relationship_status"
                onChange={(e) => {
                  handleInput(e)
                }}
              >
                <FormControlLabel className="status_sec" value="Single" control={<Radio />} label="Single" />
                <FormControlLabel value="Married" control={<Radio />} label="Married" />
                <FormControlLabel value="Separated" control={<Radio />} label="Separated" />
                <FormControlLabel value="Divorced" control={<Radio />} label="Divorced" />
                <FormControlLabel value="Widowed" control={<Radio />} label="Widowed" />
              </RadioGroup>
            </FormControl>
          </div>

        </div>

        <div className="information_details_sec">
          <h1>Personality and Life</h1>

          <div className="from_sec_main_wrpr">

            <div className="from_field sub_sec">
              <InputLabel>Describe Your Personality</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Describe Your Personality"
                name="describe_your_personality"
                value={user?.describe_your_personality ? user.describe_your_personality : ""}
                onChange={handleInput}
                error={error.describe_your_personality ? true : false}
                helperText={error.describe_your_personality}
                onKeyDown={() => {
                  error.describe_your_personality = "";
                }}
              />
            </div>

            <div className="from_field sub_sec">

              <InputLabel>Primary Strengths and Talents</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Primary Strengths and Talents"
                name="primary_sengths_talents"
                value={user?.primary_sengths_talents ? user.primary_sengths_talents : ""}
                onChange={handleInput}
                error={error.primary_sengths_talents ? true : false}
                helperText={error.primary_sengths_talents}
                onKeyDown={() => {
                  error.primary_sengths_talents = "";
                }}
              />
            </div>

            <div className="from_field sub_sec">

              <InputLabel>Life Areas Currently Fulfilling</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Life Areas Currently Fulfilling"
                name="life_areas_currently_fulfilling"
                value={user?.life_areas_currently_fulfilling ? user.life_areas_currently_fulfilling : ""}
                onChange={handleInput}
                error={error.life_areas_currently_fulfilling ? true : false}
                helperText={error.life_areas_currently_fulfilling}
                onKeyDown={() => {
                  error.life_areas_currently_fulfilling = "";
                }}
              />
            </div>

            <div className="from_field sub_sec">

              <InputLabel>Life Areas Needing Improvement</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Life Areas Needing Improvement"
                name="life_areas_needing_improvement"
                value={user?.life_areas_needing_improvement ? user.life_areas_needing_improvement : ""}
                onChange={handleInput}
                error={error.life_areas_needing_improvement ? true : false}
                helperText={error.life_areas_needing_improvement}
                onKeyDown={() => {
                  error.life_areas_needing_improvement = "";
                }}
              />
            </div>

            <div className="from_field sub_sec">

              <InputLabel>Long-Term Goals and Aspirations</InputLabel>


              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Long-Term Goals and Aspirations"
                name="long_term_goals_and_aspirations"
                value={user?.long_term_goals_and_aspirations ? user.long_term_goals_and_aspirations : ""}
                onChange={handleInput}
                error={error.long_term_goals_and_aspirations ? true : false}
                helperText={error.long_term_goals_and_aspirations}
                onKeyDown={() => {
                  error.long_term_goals_and_aspirations = "";
                }}
              />
            </div>

            <div className="from_field sub_sec">

              <InputLabel>Major Life Events/Experiences</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Major Life Events/Experiences"
                name="major_life_events_experiences"
                value={user?.major_life_events_experiences ? user.major_life_events_experiences : ""}
                onChange={handleInput}
                error={error.major_life_events_experiences ? true : false}
                helperText={error.major_life_events_experiences}
                onKeyDown={() => {
                  error.major_life_events_experiences = "";
                }}
              />
            </div>

          </div>

          <div className="user_from_main_wrpr">
            <div className="from_field sub_sec from_field_part2 switch_sec_wrpr">
              <InputLabel> Stress Management (Do you find it challenging to handle stress? Yes/No)</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    name='stress_management'
                    checked={user?.stress_management || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />


            </div>

            <div className="from_field sub_sec from_field_part2 switch_sec_wrpr">
              <InputLabel>Work-Life Balance (Do you feel you have a good balance? Yes/No)</InputLabel>


              <FormControlLabel
                control={
                  <Switch
                    name='Work_Life_balance'
                    checked={user?.Work_Life_balance || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>
          </div>

        </div>

        <div className="user_from_main_wrpr">

          <div className="information_details_sec prsnl_infrm_main">
            <h1>Relationships and Social Dynamics</h1>

            <div className="from_field sub_sec">

              <InputLabel>Current Relationship with Family and Friends</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Current Relationship with Family and Friends"
                name="current_relationship_with_family_and_friends"
                value={user?.current_relationship_with_family_and_friends ? user.current_relationship_with_family_and_friends : ""}
                onChange={handleInput}
                error={error.current_relationship_with_family_and_friends ? true : false}
                helperText={error.current_relationship_with_family_and_friends}
                onKeyDown={() => {
                  error.current_relationship_with_family_and_friends = "";
                }}
              />

            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Focus on Specific Relationships (Yes/No)</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    name='focus_on_specific_relationships'
                    checked={user?.focus_on_specific_relationships || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Concerns About Romantic Life (Yes/No)</InputLabel>


              <FormControlLabel
                control={
                  <Switch
                    name='concerns_about_romantic_life'
                    checked={user?.concerns_about_romantic_life || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Social Life Fulfillment (Yes/No)</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    name='social_life_fulfillment'
                    checked={user?.social_life_fulfillment || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

          </div>


          <div className="information_details_sec prsnl_infrm_main">
            <h1>Current Situations and Challenges</h1>

            <div className="from_field sub_sec">

              <InputLabel>Biggest Current Challenges</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Biggest Current Challenges"
                name="biggest_current_challenges"
                value={user?.biggest_current_challenges ? user.biggest_current_challenges : ""}
                onChange={handleInput}
                error={error.biggest_current_challenges ? true : false}
                helperText={error.biggest_current_challenges}
                onKeyDown={() => {
                  error.biggest_current_challenges = "";
                }}
              />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Guidance on Specific Decision/Situation (Yes/No)</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    name='guidance_on_specific_decision'
                    checked={user?.guidance_on_specific_decision || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Ongoing Projects or Plans (Yes/No)</InputLabel>


              <FormControlLabel
                control={
                  <Switch
                    name='ongoing_projects'
                    checked={user?.ongoing_projects || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

          </div>

        </div>

        <div className="user_from_main_wrpr">

          <div className="information_details_sec prsnl_infrm_main">
            <h1>Spiritual and Personal Growth</h1>



            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Spiritual or Personal Growth Practices</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    name='spiritual_or_personal_growth_practices'
                    checked={user?.spiritual_or_personal_growth_practices || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Exploring Self-Discovery/Personal Development</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    name='exploring_self_discovery'
                    checked={user?.exploring_self_discovery || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Belief in External Influences on Life (Yes/No)</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    name='belief_in_external_influences_on_life'
                    checked={user?.belief_in_external_influences_on_life || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

          </div>

          <div className="information_details_sec prsnl_infrm_main">
            <h1>Goals for the Reading</h1>

            <div className="from_field sub_sec">

              <InputLabel>Achieve from the Reading</InputLabel>

              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Achieve from the Reading"
                name="achieve_from_the_reading"
                value={user?.achieve_from_the_reading ? user.achieve_from_the_reading : ""}
                onChange={handleInput}
                error={error.achieve_from_the_reading ? true : false}
                helperText={error.achieve_from_the_reading}
                onKeyDown={() => {
                  error.achieve_from_the_reading = "";
                }}
              />

            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Specific Questions/Topics to Address</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    name='specific_questions_topics_to_address'
                    checked={user?.specific_questions_topics_to_address || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Goals or Outcomes for the Readin</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    name='goals_or_outcomes_for_the_reading'
                    checked={user?.goals_or_outcomes_for_the_reading || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Preference for Practical Advice vs. Spiritual Insights</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    name='Preference_for_practical_advice_vs_spiritual_insights'
                    checked={user?.Preference_for_practical_advice_vs_spiritual_insights || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

          </div>

        </div>

        <div className="user_from_main_wrpr">

          <div className="information_details_sec prsnl_infrm_main">
            <h1>Current Situation</h1>

            <div className="from_field sub_sec">

              <InputLabel>Main Concern or Question Right Now</InputLabel>


              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Main Concern or Question Right Now"
                name="main_concern_or_question_right_now"
                value={user?.main_concern_or_question_right_now ? user.main_concern_or_question_right_now : ""}
                onChange={handleInput}
                error={error.main_concern_or_question_right_now ? true : false}
                helperText={error.main_concern_or_question_right_now}
                onKeyDown={() => {
                  error.main_concern_or_question_right_now = "";
                }}
              />
            </div>
          </div>

          <div className="information_details_sec prsnl_infrm_main">
            <h1>Additional Information</h1>

            <div className="from_field sub_sec">

              <InputLabel>Anything Else to Share</InputLabel>


              <TextareaAutosize
                minRows={4}
                className="addemail"
                label="Anything Else to Share"
                name="anything_else_to_share"
                value={user?.anything_else_to_share ? user.anything_else_to_share : ""}
                onChange={handleInput}
                error={error.anything_else_to_share ? true : false}
                helperText={error.anything_else_to_share}
                onKeyDown={() => {
                  error.anything_else_to_share = "";
                }}
              />
            </div>

            <div className="from_field sub_sec switch_sec_wrpr">
              <InputLabel>Concerns/Reservations About the Consultation (Yes/No)</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    name='concerns_reservations_about_the_consultation'
                    checked={user?.concerns_reservations_about_the_consultation || false}
                    onChange={(event) => {
                      handleInput(event);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } />
            </div>

          </div>

        </div>




        {/* <div>
          <button
            className="addemail"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>

        </div> */}

      </div>
    </>
  )
}

export default UserForm