export const BASE_URL = 'https://wfr9bu9th2.execute-api.us-east-1.amazonaws.com/dev';

export const reqBody = {
  "condition": {
    "limit": 10,
    "skip": 0
  },
  "sort": {
    "type": "desc",
    "field": "_id"
  },
  "searchcondition": {
    "treatment_status": 0,
    "doctor_id": "617a336c84463f4ab9a0cb13"
  }
}